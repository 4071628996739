export const urlForProtocol = (protocol) => {

  // 1 = Optimism
  // 2 = Arbitrum
  // 3 = Polygon
  // 4 = Perpetual
  // 5 = Celo 
  // 6 = Base
  // 7 = Bsc
  // 8 = Avalanch
  // else mainnet 

  return protocol === 1 ? process.env.REACT_APP_THE_GRAPH_API + "Cghf4LfVqPiFw6fp6Y5X5Ubc8UpmUhSfJL82zwiBFLaj" : 
    protocol === 2 ? process.env.REACT_APP_THE_GRAPH_API + "FbCGRftH4a3yZugY7TnbYgPJVEv2LvMT6oF1fxPe9aJM" :
    protocol === 3 ? process.env.REACT_APP_THE_GRAPH_API + "3hCPRGf4z88VC5rsBKU5AA9FBBq5nF3jbKJG7VZCbhjm" :
    protocol === 4 ? "https://api.thegraph.com/subgraphs/name/perpetual-protocol/perpetual-v2-optimism" :
    protocol === 5 ? process.env.REACT_APP_THE_GRAPH_API + "ESdrTJ3twMwWVoQ1hUE2u7PugEHX3QkenudD6aXCkDQ4" :
    protocol === 6 ? process.env.REACT_APP_THE_GRAPH_API + "43Hwfi3dJSoGpyas9VwNoDAv55yjgGrPpNSmbQZArzMG" :
    protocol === 7 ?  process.env.REACT_APP_THE_GRAPH_API + "F85MNzUGYqgSHSHRGgeVMNsdnW1KtZSVgFULumXRZTw2" :
    protocol === 8 ? process.env.REACT_APP_THE_GRAPH_API + "GVH9h9KZ9CqheUEL93qMbq7QwgoBu32QXQDPR6bev4Eo" :
    process.env.REACT_APP_THE_GRAPH_API + "5zvR82QoaXYFyDEKLZ9t6v9adgnptxYpKpSbxtgVENFV";

}

export const minTvl = (protocol) => {
  return protocol === 0 ? 10000 : 1;
}

export const requestBody = (request) => {
  
  if(!request.query) return;

  const body = {
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        query: request.query,
        variables: request.variables || {}
      })
  }

  if (request.signal) body.signal = request.signal;
  return body;

}


